<template>
  <div class="fav-card__container flex-col" :class="favList ? 'display-flex' : 'display-flex-center'">
	<template v-if="!favList">
		<p class="fav-subtitle fav-subtitle-1line" >
			{{ isMobile ? $t("TAP_ON") : $t("CLICK_ON") }}
			<img class="fav-icon__sample" src="../../../static/images/icons/icon-star-grey.png"  alt=""/>{{ $t("ADD_MATCH") }}
		</p>
		<FavCardMatch />
	</template>
    <MatchContent
      ref="matchContentChild"
      class="match-content__wrapper"
      style="min-height: 15rem"
      type="fav"
	  @favChangeEmit="listenFavChangeMatchContentEvent"
      @isDataReturn="isDataReturn"
    ></MatchContent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FavCardMatch from "./components/FavCardMatch.vue";
import MatchContent from "@/components/MatchContent2.vue";
import moment from "moment";

export default {
  components: { FavCardMatch, MatchContent },
  data() {
    return {
      favList: true,
    };
  }, 
  mounted() {
    this.commonVerifyLogin();
    if (this.isLogin) {
       this.init();
    }
  },
  watch: {
 	  userSwitchCountryLang: {
      deep: true,
      handler(newVal, oldVal) {
          this.init();
      },
    },
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo", "currentLocale", "isMobile", "currentLanguageObj","userSwitchCountryLang"]),
  },
  methods: {
    ...mapActions(["getFavMatchList", "commonVerifyLogin"]),

    init() {
      this.getFavMatchListData();
    },

    getFavMatchListData() {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
        date: moment(new Date()).format("YYYY-MM-D"),
        isCallInterval: false,
        isLiveOnly: false,
      };

      this.$refs.matchContentChild.getFavMatchListData(params);
    },
    isDataReturn(data) {
      this.favList = data;
    },
	listenFavChangeMatchContentEvent() {
	}
  },
};
</script>

<style scoped></style>
